import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import SEO from '../components/seo'
import Hero from '../components/hero'
import VerticalCard from "../components/vertical-card";
import Wrapper from "../components/wrapper";

export const query = graphql`
query ProjectsPageQuery {
    projects: allSanityProject(
        filter: {slug: {current: {ne: null}}}
        sort: {fields: [order], order: DESC}
    ) {
        edges {
            node {
                title
                slug {
                    current
                }
                excerpt
                images {
                    ...Image
                }
                squareImages: images {
                    ...SquareImage
                }
            }
        }
    }
}
`

class ProjectsPage extends React.Component {
    constructor(props) {
        super(props);
        const {data, errors} = props
        this.state = {
            siteSettings: (data || {}).site || {
                title: 'iSi',
                description: 'iSi',
                keywords: ['isi']
            },
            frontPage: (data || {}).frontPage,
            projects: (data || {}).projects
        }
    }

    render() {
        return (
            <>
                <SEO
                    title='Prosjekter'
                    description={this.state.siteSettings.description}
                    keywords={this.state.siteSettings.keywords}
                />
                <Hero
                    title='Prosjekter'
                />
                <Container>
                    <div className='md:px-130 md:pb-24'>
                        <div className='-mx-6 md:mx-0 pb-4'>
                            {this.state.projects && this.state.projects.edges.map(({node}, index) => (
                                <div key={`project-${index}`}>
                                    <VerticalCard
                                        odd={index % 2 === 0}
                                        url={`/project/${node.slug.current}`}
                                        title={node.title}
                                        text={node.excerpt}
                                        image={node.images.length > 0 ? node.images[0].asset.fluid : null}
                                        squareImage={node.squareImages.length > 0 ? node.squareImages[0].asset.fluid : null}
                                        readMoreText='Les mer om prosjektet'
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </Container>
            </>
        )
    }
}

export default ProjectsPage
