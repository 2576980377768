import React from 'react'
import {Link} from 'gatsby'
import Img from 'gatsby-image'
import {format} from 'date-fns'

const VerticalCard = ({url, image, squareImage, title, text, readMoreText, date = null, odd = false}) => {
    return (
        <Link to={url}
              className='block relative group no-underline md:hover:bg-white bg-brand-blue md:bg-white md:bg-opacity-36 md:hover:bg-opacity-50 transition-all duration-300 ease md:mb-8'>
            <span className="md:hidden pointer-events none absolute z-40 inset-0 flex items-center justify-center">
                <span
                    className="h-6 w-6 bg-brand-red-light rounded-full flex items-center justify-center text-white">
                    <span className="fa fa-chevron-right"/>
                </span>
            </span>
            <span className='flex flex-row items-center'>
            <span className={`w-1/2 md:w-3/5 h-full md:h-auto ${odd ? 'order-1' : 'order-2 md:order-1'}`}>
                {image && (
                    <span className='hidden md:block pb-1/2 relative'>
                      <span
                          className='absolute top-0 left-0 bottom-0 right-0 overflow-hidden'>
                        <Img fluid={image}
                             className='object-cover transition duration-500 ease-in-out transform group-hover:scale-102'/>
                      </span>
                    </span>
                )}
                {squareImage && (
                    <span className='block md:hidden pb-full relative h-full w-full'>
                      <span
                          className='absolute top-0 left-0 bottom-0 right-0 overflow-hidden'>
                        <Img fluid={squareImage}
                             className='object-cover transition duration-500 ease-in-out transform group-hover:scale-105'/>
                      </span>
                    </span>
                )}
            </span>
            <span
                className={`flex flex-col md:px-10 mb-4 w-1/2 md:flex-1 p-6 md:p-0 ${odd ? 'order-2 text-left' : 'order-1 md:order-2 text-right md:text-left'}`}>
              <h2 className='md:font-heading text-base md:text-2xl text-white leading-tight md:text-brand-body md:font-semibold mb-0 md:mb-4 transition-colors duration-100 ease-in-out break-words'>{title}</h2>
                {date && (
                    <span className='block mb-4'>
                  <span className='text-sm'>{format(date, 'DD/MM/YYYY HH:mm')}</span>
                </span>
                )}
                <span className='hidden md:block leading-snug mb-4 flex-1 md:max-w-3xl text-base'>{text}</span>
            </span>
          </span>
        </Link>
    )
}

export default VerticalCard
